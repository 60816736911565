import { Layout } from 'layout';
import { GetServerSidePropsContext } from 'next';
import Image from 'next/image';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

import { Button } from 'common/components/Button';
import { IServerProps } from 'common/types/common';

const NotFound: React.FC = () => {
  const { t } = useTranslation('meta');

  const seo = {
    alternate: t('notFound.alternate', { returnObjects: true }),
    description: t('notFound.description'),
    image: t('notFound.image'),
    title: t('notFound.title'),
  };
  return (
    <Layout dark seo={seo}>
      <section className="bg-gradient relative min-h-[60vh] w-full items-center overflow-hidden">
        <div className="container flex flex-col items-center justify-center p-4 py-32 text-white">
          <Image
            alt="Nordit - not found"
            className="h-[440px] w-auto max-w-[110vw] object-contain"
            src="https://firebasestorage.googleapis.com/v0/b/nordit-83a0b.appspot.com/o/notFound.png?alt=media&token=4947fc8d-4341-4484-a133-25b5af092598"
            height="805"
            width="1280"
          />

          <h1 className="-mt-16 mb-4 font-bold uppercase lg:text-4xl">
            {t('common:pageNotFound')}
          </h1>
          <div className="mb-6 lg:max-w-[30vw]">{t('common:pleaseGoBackToTheHomepage')}</div>

          <Button href="/">{t('common:returnToHomepage')}</Button>
        </div>
      </section>
    </Layout>
  );
};

export const getStaticProps = async (
  context: GetServerSidePropsContext,
): Promise<IServerProps<INotFoundPageProps>> => {
  const locale = await serverSideTranslations(context.locale || 'en', ['common', 'meta']);

  return {
    props: {
      data: {},
      ...locale,
    },
  };
};

interface INotFoundPageProps {
  data: Record<string, never>;
}

export default NotFound;
